export default {
  container: 'mountNode', // The container id or HTML node of the graph canvas. | kaaro: To be replaced by Graph Container Ref
  width: 1500,
  height: 650,
  animate: true, // Boolean, whether to activate the animation when global changes happen
  // animateCfg: {
  //   duration: 200, // Number, the duration of one animation
  //   easing: 'easecubic', // String, the easing function
  // },
  fitView: true,
  layout: {
    type: 'Dagre',
    center: [500, 300], // The center of the graph by default
    gravity: 10,
    nodeCollideStrength: 1,
    comboCollideStrength: 1,
    controlPoints: true,
    linkDistance: 100,
    nodeStrength: 1000,
    edgeStrength: 300,
    rankdir: 100, // dropdown
    align: null, // dropdown
    maxIteration: 100, // slider
    unitRadius: 50, // slider
    focusNode: null, // dropdown with search
    // nodeSize: null, // slider
    sweep: null, // slider
    startRadius: null, // slider
    endRadius: null, // slider
    clockwise: null, // toggle
    // gravity: null, // slider
    speed: null, // slider
    clusterGravity: null, // slider
    row: null, // slider
    column: null, // slider
    // rankseparation: 10,
    // nodeseparation: 10,
    // nodeSize: 30,
    preventOverlap: true,
    preventNodeOverlap: true,
  },
  enabledStack: true,
  defaultCombo: {
    type: 'rect',
    /* The minimum size of the combo. combo 最小大小 */
    /* style for the keyShape */
    style: {
      lineWidth: 1,
      opacity: 0.1,
      fill: '#010148',
    },
    labelCfg: {
      position: 'top',
      color: '#fff',
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },

  modes: {
    default: [
      'drag-node',
      {
        type: 'zoom-canvas',
        enableOptimize: true,
        optimizeZoom: 0.4,
        sensitivity: 3,
        minZoom: 1,

      },
      {
        type: 'drag-canvas',
        enableOptimize: true,
      },
      'click-add-node',
      'drag-combo',
      'click-select',
      'collapse-expand-combo',
      {
        type: 'lasso-select',
        delegateStyle: {
          fill: '#fdc400',
          fillOpacity: 0.05,
          stroke: '#fdc400',
          lineWidth: 1,
        },
        onSelect: () => { },
        trigger: 'shift',
      },
      {
        type: 'activate-relations',
      },
    ],

    defaultMain: [
      'drag-node',
      'click-select',
      {
        type: 'zoom-canvas',
        enableOptimize: true,
        optimizeZoom: 0.4,
        sensitivity: 3,
        minZoom: 0.5,
      },
      {
        type: 'drag-canvas',
        enableOptimize: true,
      },
      'click-add-node',
      'drag-combo',
      'collapse-expand-combo',

      {
        type: 'lasso-select',
        delegateStyle: {
          fill: '#fdc400',
          fillOpacity: 0.05,
          stroke: '#fdc400',
          lineWidth: 1,
        },
        onSelect: () => { },
        trigger: 'shift',
      },
    ],
    defaultMainCreateEdge: [
      'drag-node',
      'click-select',
      'create-edge',
      {
        type: 'zoom-canvas',
        enableOptimize: true,
        optimizeZoom: 0.4,
        sensitivity: 3,
        minZoom: 0.5,
      },
      {
        type: 'drag-canvas',
        enableOptimize: true,
      },
      'click-add-node',
      'drag-combo',
      'collapse-expand-combo',

      {
        type: 'lasso-select',
        delegateStyle: {
          fill: '#fdc400',
          fillOpacity: 0.05,
          stroke: '#fdc400',
          lineWidth: 1,
        },
        onSelect: () => { },
        trigger: 'shift',
      },

    ],
    hsl: [
      'activate-relations',
      'activate-node',
      'activate-edge',
      'click-select',
      'drag-node',
      'drag-canvas',
      'zoom-canvas',
    ],
  },
  nodeStateStyles: {
    active: {
      opacity: 1,
    },
    inactive: {
      opacity: 0.1,
    },
    searchEd: {
      stroke: '#fff',
      fill: '#0fa',
      lineWidth: 1,
      opacity: 1,
      shadowColor: '#0fa',
      shadowBlur: 10,
    },
    opacityO: {
      opacity: 0.1,
    },
    lockedState: {
      // size: '30',
      stroke: '#0B5E39',
      fill: '#00000000',
      lineWidth: 1,
      shadowColor: '#0B5E39',
      shadowBlur: 17,
      opacity: 1,
    },
    searchRelatedEd: {
      stroke: '#fff',
      fill: '#0fa',
      lineWidth: 1,
      shadowColor: '#0fa',
      shadowBlur: 10,
    },
    focusEd: {
      stroke: '#fff',
      fill: '#010148',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#fff',
      shadowBlur: 1,
    },
    relatedEdIndex: {
      stroke: '#fff',
      fill: '#010148',
      opacity: 0.5,
      lineWidth: 1,
      shadowColor: '#fff',
      shadowBlur: 1,
    },
    relatedEd: {
      stroke: '#010148',
      fill: '#fce181',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#010148',
      shadowBlur: 1,
    },
    spNode: {
      size: '100',
      fill: '#fce181',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#010148',
      shadowBlur: 3,
    },
    current_ent: {
      stroke: '#fdc400',
      fill: '#010148',
      lineWidth: 1,
      shadowColor: '#fdc400',
      shadowBlur: 2,
    },
    'tjNodeState:wikilink': {},
    'tjNodeState:custom-node': {
      size: [200],
      stroke: '#fff',
      fill: '#fc8217',
      lineWidth: 1,
      type: 'triangle',
      // shadowOffsetX: 10,
      // shadowOffsetY: 10,
      shadowColor: '#659dbd',
      shadowBlur: 10,
      'node-label': {
        /* label's position, options: center, top, bottom, left, right */
        position: 'top',
        /* label's offset to the keyShape, 4 by default */
        offset: 2,
        /* label's style */

        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
      },
    },
    'tjNodeState:function-node': {
      size: [200],
      stroke: '#fff',
      fill: '#F21557',
      lineWidth: 1,
      type: 'triangle',
      // shadowOffsetX: 10,
      // shadowOffsetY: 10,
      shadowColor: '#659dbd',
      shadowBlur: 10,
      'node-label': {
        /* label's position, options: center, top, bottom, left, right */
        position: 'top',
        /* label's offset to the keyShape, 4 by default */
        offset: 2,
        /* label's style */

        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
      },
    },
    'tjNodeState:note-text': {
      fill: '#8a661e',
      stroke: '#888',
    },
    'tjNodeState:note-image': {
      'node-label': {
        position: 'bottom',
      },
      clipCfg: {
        show: true,
        type: 'circle',
        // circle
        r: 25,
      },
    },
    'tjNodeState:note-recording': {
      fill: '#00ff95',
      stroke: '#888',
    },
    'tjNodeState:note-3d': {
      fill: '#fce181',
      stroke: '#888',
    },
    'tjNodeState:note-page': {
      fill: '#8c00ff',
      stroke: '#888',
    },
    'tjNodeState:note-video': {
      fill: '#cf0433',
      stroke: '#888',
    },
    'tjNodeState:user': {
      fill: '#b3fff9',
      stroke: '#888',
    },
  },

  edgeStateStyles: {
    hover: {
      stroke: '#75fdff', // Set the desired color for the focused edge
      // fill: '#75fdff', // Set the desired fill color for the focused edge
      shadowColor: '#05fbff',
      shadowBlur: 10,
    },
    active: {
      opacity: 1,
    },
    inactive: {
      opacity: 0.1,
    },

    focusEd: {
      stroke: '#fff',
      fill: '#fce181',
      opacity: 0.6,
      lineWidth: 1,
      shadowColor: '#fce181',
      shadowBlur: 1,
    },
    searchEd: {
      stroke: '#fff',
      fill: '#fce181',
      opacity: 0.6,
      lineWidth: 1,
      shadowColor: '#fce181',
      shadowBlur: 1,
    },
    relatedEdIndex: {
      stroke: '#fff',
      fill: '#fff',
      opacity: 0.5,
      lineWidth: 1,
      shadowColor: '#fff',
      shadowBlur: 1,
    },
    spEdge: {
      fill: '#010148',
      opacity: 1,
      lineWidth: 4,
      shadowColor: '#fff',
      shadowBlur: 3,
    },
    relatedEd: {
      stroke: '#fff',
      // fill: '#fff',
      opacity: 1,
      lineWidth: 1,
      shadowColor: '#fff',
      shadowBlur: 1,
    },

    opacityO: {
      opacity: 0.1,
    },
  },

  comboStateStyles: {
    focusEd: {
      stroke: '#fff',
      fill: '#333',
      opacity: 0.6,
      lineWidth: 1,
      shadowColor: '#333',
      shadowBlur: 1,
    },
  },

  defaultNode: {
    size: '20',
    type: 'circle',
    style: {
      stroke: '#010148',
      lineWidth: 1,
    },
    labelCfg: {
      position: 'top',
      color: '#fff',
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },
  defaultEdge: {
    // type: 'arc',
    type: 'cubic-vertical',
    controlPoints: true,
    labelCfg: {
      position: 'top',
      refY: -10,
      color: '#fff',
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },
};
